import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useUser } from '@context/User.context';
import { HelpBox } from '@GDM/HelpBox/HelpBox';
import useTranslation from '@hooks/useTranslation';
import { ContractForm } from '@utils/types/contract';
import classNames from 'classnames';
import { Path, UseFormReturn } from 'react-hook-form';
import { HelpBoxProvider } from '../contexts/helpBoxContext';
import { FieldDefinition, RegisteredFieldsTracker, RegisteredSectionFields } from '../contexts/registeredFields';
import { ContractFormGlobals, ContractFormSection, ContractFormSectionName } from '../hooks/useContractForm/types';
import { ContractFormQueries } from '../hooks/useContractForm/useContractFormQueries';
import { useHelpBox } from '../hooks/useHelpBox';
import { Nav, NavItem } from './components/Nav';
import styles from './contractForm.module.scss';

export const Form = ({
  readOnly,
  isLoading,
  sections,
  globals,
  queries,
  showProgress,
  showHelpBoxes,
  form,
}: {
  form: UseFormReturn<ContractForm>;
  isLoading: boolean;
  readOnly: boolean;
  sections: ContractFormSection[];
  globals: ContractFormGlobals;
  queries: ContractFormQueries;
  showProgress?: boolean;
  showHelpBoxes?: boolean;
}) => {
  const aside = useRef<HTMLDivElement>(null);
  const { helpBoxStyle, infoBox, handleBlur, handleFocus } = useHelpBox(aside);
  const { t } = useTranslation();
  type SectionsProgress = Partial<Record<ContractFormSectionName, number>>;
  const [sectionsProgress, setSectionsProgress] = useState<SectionsProgress>(
    sections.reduce(
      (previous, { name }) => ({
        ...previous,
        [name]: 0,
      }),
      {},
    ),
  );

  const updateSectionProgress = useMemo(
    () => (name: ContractFormSectionName) => (progress: number) => {
      setSectionsProgress((previous) => ({ ...previous, [name]: progress }));
    },
    [],
  );

  return (
    <>
      <div
        className={classNames(styles.wrapper, styles.stepper, {
          'mt-4': showHelpBoxes || showProgress,
          unavailable: isLoading,
        })}
      >
        {showProgress && (
          <div className={styles.nav}>
            <Nav>
              {sections.map(({ name, title, displayPredicate = () => true }) => {
                const progress = sectionsProgress[name] || 0;
                if (!displayPredicate(form, globals)) return null;

                return <NavItem key={name} title={title} progress={progress} isValid={progress >= 100} />;
              })}
            </Nav>
            {showHelpBoxes && (
              <div className={classNames(styles.aside)}>
                {infoBox && (
                  <HelpBox
                    {...infoBox}
                    variant="light"
                    title={t(infoBox.title)}
                    text={t(infoBox.text)}
                    className={styles['help-box']}
                    style={helpBoxStyle}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div className={styles.main}>
          <HelpBoxProvider handleBlur={handleBlur} handleFocus={handleFocus}>
            {sections?.map((section) => {
              return (
                <FormSectionWrapper
                  {...section}
                  globals={globals}
                  queries={queries}
                  form={form}
                  readOnly={readOnly}
                  key={section.name}
                  onProgressChange={updateSectionProgress(section.name)}
                />
              );
            })}
          </HelpBoxProvider>
        </div>
      </div>
    </>
  );
};

const FormSectionWrapper = ({
  name,
  title,
  Component,
  permissions,
  displayPredicate = () => true,
  globals,
  queries,
  form,
  readOnly,
  onProgressChange,
}: ContractFormSection & {
  form: UseFormReturn<ContractForm>;
  readOnly: boolean;
  globals: ContractFormGlobals;
  queries: ContractFormQueries;
  onProgressChange: (progress: number) => void;
}) => {
  const [fields, setFields] = useState<RegisteredSectionFields>(() => new Map<string, FieldDefinition>());

  useEffect(() => {
    const subscription = form.watch(() => {
      const requiredFields = [...fields.entries()].filter(([, { required }]) => required).map(([name]) => name);
      const filledRequiredFieldsValues = form.getValues(requiredFields as Path<ContractForm>[]).filter(Boolean);
      const progress = (100 * filledRequiredFieldsValues.length) / requiredFields.length;
      onProgressChange?.(progress);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [fields, form, onProgressChange]);

  const { isAuthorized } = useUser();
  if (permissions && !isAuthorized(permissions)) return null;

  if (!displayPredicate(form, globals)) return null;

  return (
    <RegisteredFieldsTracker key={name} onChange={setFields}>
      <Component title={title} readOnly={readOnly} globals={globals} queries={queries} form={form} />
    </RegisteredFieldsTracker>
  );
};
