import React, { useContext } from 'react';
import localeContext from '@context/locale.context';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import invoicesContext from 'pages/sales-management/invoicing/invoicesContext';
import indexCsvExport from 'pages/sales-management/invoicing/utils/csvExports/indexCsvExport';
import loadCurveCsvExport from 'pages/sales-management/invoicing/utils/csvExports/loadCurveCsvExport';
import styles from '../actions.module.scss';

type DownloadCsvButtonProps = {
  invoiceType: MeterInvoiceSource;
  startDate?: Date | null;
  endDate?: Date | null;
};

const DownloadCsvButton = ({ invoiceType, startDate, endDate }: DownloadCsvButtonProps) => {
  const locale = useContext(localeContext);
  const { t } = useTranslation();
  const { filteredInvoices } = useContext(invoicesContext);

  const handleExportCsvLoadCurveClick = () => {
    fileDownload(loadCurveCsvExport(filteredInvoices, locale), 'facturation.csv', 'text/csv;charset=utf-8', '\uFEFF');
  };

  const handleExportCsvIndexClick = () => {
    const start = dayjs(startDate).format('YYYYMM');
    const end = dayjs(endDate).format('YYYYMM');

    const fileName = `facturation_index_${start}_${end}.csv`;

    fileDownload(indexCsvExport(filteredInvoices, locale), fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <button
      className={styles['menu-element']}
      onClick={invoiceType === 'index' ? handleExportCsvIndexClick : handleExportCsvLoadCurveClick}
    >
      {t('common.csv')}
    </button>
  );
};

export default DownloadCsvButton;
