import React, { useEffect } from 'react';
import { ContractFormGlobals } from '@pages/Contracts/Contract/hooks/useContractForm/types';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { ContractForm } from '@utils/types/contract/form';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../hooks/useDateValidation';
import { ENERGY_PRODUCTION_SUBPEERIOD_CONTRACT_TYPES } from './constants/contractTypesSubPeriodTypes';
import { DEFAULT_PRICE_RULES_OPTIONS, NEG_PRICE_RULE_CONTRACT_OPTIONS_MAP } from './constants/negPriceRules';
import { PRICE_TYPE_CONTRACT_OPTIONS_MAP } from './constants/priceTypes';
import styles from './sub-period.module.scss';
import { EnergyProductionSubPeriod } from './templates/EnergyProduction/EnergyProduction';
import { SwapSubPeriod } from './templates/Swap';
import { SwapPlSubPeriod } from './templates/SwapPl';
import { SwapUkSubPeriod } from './templates/SwapUk';

export const SubPeriod = ({
  index,
  readOnly,
  isCollapsed,
  globals: { installation },
  onDatePeriodClick,
  form,
  header,
  datesValidation,
  preSelectedPeriod,
  queries,
}: {
  index: number;
  periodId: string;
  header: React.ReactNode;
  isCollapsed: boolean;
  readOnly: boolean;
  onDatePeriodClick: (period: string, index: number) => void;
  form: UseFormReturn<ContractForm>;
  queries: ContractFormQueries;
  globals: ContractFormGlobals;
  preSelectedPeriod: string | undefined;
  datesValidation: DateValidators;
}) => {
  const country = installation?.country;
  const [contractType, currency, contractStartDate, contractEndDate] = form.watch([
    'type',
    'currency',
    'start_date',
    'end_date',
  ]);

  useEffect(() => {
    const subsciption = form.watch(() => {
      form.trigger(`contract_sub_periods_attributes.${index}.dates`);
    });

    return subsciption.unsubscribe;
  }, [form, index]);

  const contractDates: [Date, Date] | null = contractStartDate &&
    contractEndDate && [contractStartDate, contractEndDate];

  const customPeriods: Array<{ name: string; dates: [Date, Date] }> | undefined =
    (contractDates && [
      ...(queries.marketFutures.data?.map((i) => ({
        name: i.name,
        dates: [new Date(i.start_date), new Date(i.end_date)] as [Date, Date],
      })) || []),
      {
        name: 'sales_management.contract_dates',
        dates: contractDates,
      },
    ]) ||
    undefined;

  const negativePriceRulesOptions =
    NEG_PRICE_RULE_CONTRACT_OPTIONS_MAP[contractType || ''] || DEFAULT_PRICE_RULES_OPTIONS;

  if (!contractType) return null;

  return (
    <div data-cy="sub-period" className={classNames(styles.container, { [styles['is-collapsed']]: isCollapsed })}>
      {header}
      {!isCollapsed && (
        <>
          {contractType === 'ContractSwapPl' && (
            <SwapPlSubPeriod
              index={index}
              form={form}
              currency={currency}
              readOnly={readOnly}
              onDatePeriodClick={(period) => onDatePeriodClick(period, index)}
              preSelectedPeriod={preSelectedPeriod}
              customPeriods={customPeriods}
              datesValidation={datesValidation}
              priceTypeOptions={PRICE_TYPE_CONTRACT_OPTIONS_MAP.ContractSwapPl || []}
            />
          )}
          {contractType === 'ContractSwapUk' && (
            <SwapUkSubPeriod
              index={index}
              form={form}
              currency={currency}
              readOnly={readOnly}
              onDatePeriodClick={(period) => onDatePeriodClick(period, index)}
              preSelectedPeriod={preSelectedPeriod}
              customPeriods={customPeriods}
              datesValidation={datesValidation}
              country={country}
              priceTypeOptions={PRICE_TYPE_CONTRACT_OPTIONS_MAP.ContractSwapUk || []}
            />
          )}
          {contractType === 'ContractSwap' && (
            <SwapSubPeriod
              index={index}
              form={form}
              currency={currency}
              readOnly={readOnly}
              onDatePeriodClick={(period) => onDatePeriodClick(period, index)}
              preSelectedPeriod={preSelectedPeriod}
              customPeriods={customPeriods}
              datesValidation={datesValidation}
              country={country}
              priceTypeOptions={(PRICE_TYPE_CONTRACT_OPTIONS_MAP.ContractSwap || []).filter((option) =>
                country !== 'FR' ? option.value !== 'm0' : true,
              )}
              negativePriceRulesOptions={negativePriceRulesOptions}
            />
          )}
          {ENERGY_PRODUCTION_SUBPEERIOD_CONTRACT_TYPES.includes(contractType) && (
            <EnergyProductionSubPeriod
              index={index}
              form={form}
              readOnly={readOnly}
              currency={currency}
              onClickPeriod={(period) => onDatePeriodClick(period, index)}
              selectedPeriod={preSelectedPeriod}
              customPeriods={customPeriods}
              datesValidation={datesValidation}
              country={country}
              queries={queries}
            />
          )}
        </>
      )}
    </div>
  );
};
