import React from 'react';
import { Text } from '@GDM/Text';
import classNames from 'classnames';
import styles from './update-modal.module.scss';

export const RevenueLabels = () => {
  return (
    <div className={styles.revenues}>
      <Text className={classNames(styles['input-label'], styles.input)} text="common.label" />
      <Text className={classNames(styles['input-label'], styles.input)} text="sales_management.invoices.revenue_type" />
      <Text className={classNames(styles['input-label'], styles.input)} text="common.production" />
      <Text className={classNames(styles['input-label'], styles.input)} text="sales_management.invoices.unit_price" />
      <Text className={classNames(styles['input-label'], styles.input)} text="common.total" />
      <div className={classNames(styles['input-label'], styles['delete-button'])} />
    </div>
  );
};
