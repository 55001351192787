import React, { useDeferredValue, useEffect, useMemo, useRef } from 'react';
import { useUser } from '@context/User.context';
import { DropDownButton } from '@GDM/Button/DropDownButton';
import { Checkbox, sectionStyles } from '@GDM/forms';
import {
  getDefaultValue,
  useBuySellOptions,
} from '@pages/Contracts/Contract/Form/sections/GeneralInfo/hooks/useBuySellOptions';
import { useConfigurableFields } from '@pages/Contracts/Contract/hooks/useConfigurableFields';
import dayjs from 'dayjs';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { DateInput } from '../../components/Inputs/Date';
import { SectionHeader } from '../../components/SectionHeader';
import { getDefaultDirection } from '../../helpers/getDefaultDirection';
import { ConfigurableFields } from './components/ConfigurableFields';
import { MainContractInfo } from './components/MainContractInfo';

export const GeneralInfo = ({
  title,
  readOnly,
  globals: { formMode, installation, offtaker },
  queries,
  form: { control, watch, setValue, trigger },
}: ContractFormSectionProps) => {
  const { account_type } = useUser();
  const [contractType, invoicingPlatform] = watch(['type', 'invoicing_platform']);
  const direction = watch('direction', getDefaultDirection(account_type));
  const deferredDirection = useDeferredValue(direction);
  const deferredInstallation = useDeferredValue(installation);
  const changeDefaultValue = useRef(['view', 'edit'].includes(formMode) ? false : true);

  const hasInstallationChanged = installation !== deferredInstallation;
  const hasDirectionChanged = direction !== deferredDirection;

  const { buyerOptions, sellerOptions } = useBuySellOptions(
    account_type,
    direction,
    queries.marketPlayers.data || [],
    queries.intermediaries.data || [],
    installation || null,
  );

  useEffect(() => {
    if (hasDirectionChanged) {
      setValue('type', null);
    }

    if (hasInstallationChanged || hasDirectionChanged) {
      /**
       * This logic exists because the hasInstallationChanged and hasDirectionChanged will trigger one time on edit mode
       * But you don't want to change the default value on the first "render" as the user expect to see accurate values
       */
      if (changeDefaultValue.current && buyerOptions && sellerOptions && installation) {
        setValue('buyer_id', getDefaultValue(buyerOptions, offtaker || null, installation));
        setValue('seller_id', getDefaultValue(sellerOptions, offtaker || null, installation));
      } else {
        changeDefaultValue.current = true;
      }
    }
  }, [hasInstallationChanged, hasDirectionChanged, setValue, buyerOptions, sellerOptions, offtaker, installation]);

  const [configurableFields, setConfigurableFields] = useConfigurableFields<ConfigurableField>('configurableFields');

  useEffect(() => {
    if (contractType === 'ContractSwapPl') setValue('currency', 'PLN');
    if (contractType === 'ContractSwapUk') setValue('currency', 'GBP');
  }, [setValue, contractType]);

  useEffect(() => {
    if (invoicingPlatform === 'cegedim') trigger('contract_nb');
  }, [invoicingPlatform, trigger]);

  const defaultStartDate = useMemo(() => new Date(), []);
  const defaultEndDate = dayjs(defaultStartDate).add(1, 'year').toDate();

  return (
    <FormSectionLayout
      title={title}
      headerActions={
        <SectionHeader>
          <DropDownButton
            tooltip="sales_management.configurable_fields.tooltip"
            className="ml-2"
            icon="Settings"
            variant="primary-2"
            position="right"
            data-cy="configurable-fields-dropdown"
            noChevron
            floating
          >
            <div className="p-2 d-flex flex-column gap-1" data-cy="configurable-fields-list">
              {CONFIGURABLE_FIELDS.map((field) => (
                <Checkbox
                  label={`sales_management.configurable_fields.${field}`}
                  checked={configurableFields.has(field)}
                  onChange={() => {
                    setConfigurableFields((prev) => {
                      const next = new Set(prev);

                      if (prev.has(field)) {
                        next.delete(field);
                      } else {
                        next.add(field);
                      }

                      return next;
                    });
                  }}
                  key={field}
                  name={`configurable-field-${field}`}
                  data-cy={`configurable-field-${field}`}
                />
              ))}
            </div>
          </DropDownButton>
        </SectionHeader>
      }
      body={
        <div className={sectionStyles.container} data-cy="general-information">
          <div className="p-3">
            <MainContractInfo
              control={control}
              invoicingPlatform={invoicingPlatform}
              readOnly={readOnly}
              queries={queries}
              installation={installation}
              contractType={contractType}
              buyerOptions={buyerOptions}
              sellerOptions={sellerOptions}
              direction={direction}
            />

            <div className={sectionStyles.row}>
              <DateInput
                id="StartDatePicker"
                name="start_date"
                control={control}
                rules={{ required: true }}
                label="common.start_date"
                readOnly={readOnly}
                data-cy="contract-start-date"
                defaultValue={defaultStartDate}
              />
              <DateInput
                id="EndDatePicker"
                name="end_date"
                control={control}
                rules={{ required: true }}
                label="common.end_date"
                readOnly={readOnly}
                data-cy="contract-end-date"
                defaultValue={defaultEndDate}
              />
            </div>

            <ConfigurableFields
              control={control}
              readOnly={readOnly}
              installation={installation}
              configurableFields={configurableFields}
            />
          </div>
        </div>
      }
    />
  );
};

const CONFIGURABLE_FIELDS = [
  'erp_code',
  'card_i',
  '_non_contract_props.installation.owner_erp_code',
  '_non_contract_props.installation.external_ref',
  'p_max',
  'signature_date',
] as const;

export type ConfigurableField = typeof CONFIGURABLE_FIELDS[number];
