export const ENERGY_PRODUCTION_SUBPEERIOD_CONTRACT_TYPES = [
  'ContractGo',
  'ContractGc',
  'ContractGo-LIGHT',
  'ContractSoa-LIGHT',
  'ContractCapaProxy',
  'ContractCr',
  'ContractCapa',
  'ContractSoa',
  'ContractCppa',
];

export const CONTRACT_TYPES_WITH_AGGREGATION_FEES = [
  'ContractGo-LIGHT',
  'ContractSoa-LIGHT',
  'ContractCapaProxy',
  'ContractCr',
  'ContractSoa',
  'ContractCppa',
];

export const CONTRACT_TYPES_WITH_INDEX_FORMULA = ['ContractGo', 'ContractGc', 'ContractSoa', 'ContractCppa'];

export const CONTRACT_TYPES_WITH_NEGATIVE_PRICES = ['ContractCr', 'ContractSoa', 'ContractCppa'];
export const CONTRACT_TYPES_WITH_PRICE_RULES = ['ContractCapaProxy', 'ContractCr', 'ContractSoa', 'ContractCppa'];
export const CONTRACT_TYPES_WITH_HEDGE_PROFILE = ['ContractSoa', 'ContractCppa'];
