import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const LoadCurveInvoices = () => {
  const [date, setDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
  const req = useInvoicesByType('load_curve', date);

  const handleDateChange = (date: Date | null) => {
    setDate(date ?? dayjs().subtract(1, 'month').toDate());
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="load_curve"
      isLoading={req.isPending}
      startDate={date}
      onDateChange={handleDateChange}
      isDateRange={false}
    />
  );
};

export default LoadCurveInvoices;
