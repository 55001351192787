import { useMutation } from '@tanstack/react-query';
import fetchWrapper from '@utils/fetch';
import { v2_contract_path, v2_contracts_path } from '@utils/routes';
import { ContractFormBody } from '@utils/types/contract/form';

export function useSubmitMutation(contractUuid: string | undefined) {
  const createMutation = useMutation({
    mutationFn: (contract: ContractFormBody) => {
      return fetchWrapper(v2_contracts_path(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ contract }),
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: (contract: ContractFormBody) => {
      return fetchWrapper(v2_contract_path(contract.id), {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ contract }),
      });
    },
  });

  return contractUuid ? updateMutation : createMutation;
}
