import React from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon/Icon';
import { v2_green_star_risks_path, v2_green_star_valuations_path } from '@utils/routes';
import { EnergyType, Option } from '@utils/types/common-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FOCUS_OPTIONS } from './constants';
import { useGreenStarGroups, useGreenStarInstallations } from './queries';
import styles from './styles.module.scss';
import { GreenstarReport, RiskAnalysisFormFilters, RiskAnalysisReportType } from './types';

const DEFAULT_PERIOD = [dayjs().subtract(1, 'year').toDate(), dayjs().add(5, 'year').toDate()];
const MAX_DATE = dayjs().add(15, 'years').toDate();

export const RiskAnalysisConfiguration = ({
  onReportGenerate,
  type,
}: {
  onReportGenerate: (report: GreenstarReport) => void;
  type: Exclude<RiskAnalysisReportType, 'counterparts'>;
}) => {
  const installationsQuery = useGreenStarInstallations();
  const groupsQuery = useGreenStarGroups();

  const installationsOptions =
    installationsQuery.data?.map((installation) => ({
      value: installation.name,
      label: installation.name,
    })) || [];

  const grappeOptions =
    groupsQuery.data?.grappes.map((grappe) => ({
      value: grappe,
      label: grappe,
    })) || [];

  const holdingOptions =
    groupsQuery.data?.holdings.map((holding) => ({
      value: holding,
      label: holding,
    })) || [];

  const spvOptions =
    groupsQuery.data?.spvs.map((spv) => ({
      value: spv,
      label: spv,
    })) || [];

  const energyOptions =
    installationsQuery.data?.reduce((acc, installation) => {
      if (!acc.find((energy) => energy.value === installation.energy)) {
        return [
          ...acc,
          {
            value: installation.energy,
            label: `energy.${installation.energy}`,
          },
        ];
      }

      return acc;
    }, [] as Option<EnergyType>[]) || [];

  const contractTypesOptions =
    groupsQuery.data?.contract_types.map((contractType) => ({
      value: contractType,
      label: contractType,
    })) || [];

  const onSubmit: SubmitHandler<RiskAnalysisFormFilters> = async (formData) => {
    const { period, installations, version, ...rest } = formData;

    const latestStatuses = ['latest', 'latest_vs_last_month', 'latest_vs_last_year'];

    const status = latestStatuses.includes(version) ? 'latest' : version;
    let compare: string | undefined;

    if (version === 'latest_vs_last_month') {
      compare = 'last_month';
    } else if (version === 'latest_vs_last_year') {
      compare = 'last_year';
    }

    const generatedRoute = type === 'valuation' ? v2_green_star_valuations_path : v2_green_star_risks_path;

    const options = {
      ...rest,
      start_date: period[0],
      end_date: period[1],
      site_name: installations,
      status,
      compare,
    };

    const url = generatedRoute(options);

    const response = await fetch(url);
    const data = await response.json();

    onReportGenerate({ data, filters: formData, exportUrl: generatedRoute({ ...options, format: 'xls' }) });
  };

  const { control, handleSubmit } = useForm<RiskAnalysisFormFilters>();

  return (
    <form className={classNames(styles['configuration-container'], 'p-3')} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex align-items-center mb-2">
        <Icon name="ChevronsDown" strokeWidth={3} size={16} />
        <div className="font-bold">CONFIGURATION</div>
      </div>
      <div className="d-flex flex-col gap-3">
        <div className="d-flex gap-4">
          <ControlledSelect
            name="primaryFocus"
            control={control}
            options={FOCUS_OPTIONS}
            label="common.focus"
            isClearable
            className={styles.select}
            defaultValue="contract_type"
          />
          {type === 'valuation' && (
            <ControlledSelect
              name="secondaryFocus"
              control={control}
              options={FOCUS_OPTIONS}
              label="common.focus"
              isClearable
              className={styles.select}
            />
          )}
        </div>
        <div className="d-flex gap-4">
          <ControlledSelect
            isMulti
            name="grappe"
            control={control}
            options={grappeOptions}
            label="risk_analysis.grappe"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="holding"
            control={control}
            options={holdingOptions}
            label="risk_analysis.holding"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="spv"
            control={control}
            options={spvOptions}
            label="sales_management.owner"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="installations"
            control={control}
            options={installationsOptions}
            label="sales_management.installation"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="energy"
            control={control}
            options={energyOptions}
            label="common.technology"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="contract_type"
            control={control}
            options={contractTypesOptions}
            label="common.filters.contract_type"
            className={styles.select}
          />
          <ControlledSelect
            name="apply_ownership_percentage"
            control={control}
            options={[
              { label: '100 %', value: false },
              { label: 'risk_analysis.consolidated', value: true },
            ]}
            label="risk_analysis.vision"
            className={styles.select}
            defaultValue={false}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4">
            <ControlledDatePicker
              name="period"
              control={control}
              picks="years"
              selectsRange
              label="common.period"
              className={styles.datepicker}
              defaultValue={DEFAULT_PERIOD}
              maxDate={MAX_DATE}
            />
            <ControlledSelect
              name="version"
              control={control}
              options={[
                { value: 'latest', label: 'risk_analysis.latest' },
                { value: 'last_month', label: 'risk_analysis.last_month' },
                { value: 'last_year', label: 'risk_analysis.last_year' },
                { value: 'latest_vs_last_month', label: 'risk_analysis.latest_vs_last_month' },
                { value: 'latest_vs_last_year', label: 'risk_analysis.latest_vs_last_year' },
              ]}
              label="risk_analysis.version"
              className={styles.select}
              defaultValue="latest"
            />
          </div>
          <Button className="align-self-end" variant="primary-2" size="xs" text="common.generate" type="submit" />
        </div>
      </div>
    </form>
  );
};
