import React, { useContext } from 'react';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { DatePicker, DatePickerProps } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import { defaultFilters, useInvoicesFilters } from '../../../pages/sales-management/invoicing/invoicesFiltersContext';
import { BookFilter } from './filters/BookFilter';
import { BuyerFilter } from './filters/BuyerFilter';
import { CancelledToggleFilter } from './filters/CancelledToggleFilter';
import { CardIFilter } from './filters/CardIFilter';
import { ContractNbFilter } from './filters/ContractNbFilter';
import { ContractTypeFilter } from './filters/ContractTypeFilter';
import { CountryFilter } from './filters/CountryFilter';
import { InstallationFilter } from './filters/InstallationFilter';
import { InvoiceStatusFilter } from './filters/InvoiceStatusFilter';
import { PreliminaryToggleFilter } from './filters/PreliminaryToggleFilter';
import { RegulToggleFilter } from './filters/RegulToggleFilter';
import { SourceFilter } from './filters/SourceFilter';

export const InvoiceFilters = (props: {
  isLight: boolean;
  hideBookSelect?: boolean;
  isDateRange?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  yearlyDate?: boolean;
  picks?: DatePickerProps['picks'];
  onDateChange?: (startDate: Date | null, endDate?: Date | null) => void;
  invoiceType: MeterInvoiceSource;
}) => {
  const { isLight, hideBookSelect, isDateRange } = props;
  const { unselectAllInvoices } = useContext(selectInvoicesContext);
  const form = useInvoicesFilters();

  form.watch(() => {
    unselectAllInvoices();
  });

  const showContractTypeFilter =
    props.invoiceType !== 'capa' && props.invoiceType !== 'index' && props.invoiceType !== 'rec';

  const handleClear = () => {
    form.reset(defaultFilters);
  };

  return (
    <Filters
      className={classNames(isLight && 'p-0')}
      onClear={handleClear}
      disableClearButton={!form.formState.isDirty}
    >
      {isDateRange && props.onDateChange && (
        <FilterContainer size="datepicker">
          <DatePicker
            startDate={props.startDate}
            endDate={props.endDate}
            selectsRange
            onChange={([startDate, endDate]) => props.onDateChange?.(startDate, endDate)}
            picks={props.picks || 'default'}
            data-cy="invoice-filters-date-picker"
          />
        </FilterContainer>
      )}
      {!isDateRange && props.onDateChange && (
        <FilterContainer size="datepicker">
          <DatePicker
            startDate={props.startDate}
            selected={props.startDate}
            onChange={(date) => props.onDateChange?.(date)}
            picks={props.yearlyDate ? 'years' : 'months'}
            data-cy="invoice-filters-date-picker"
          />
        </FilterContainer>
      )}

      {!isLight && (
        <FilterContainer size="select">
          <InstallationFilter />
        </FilterContainer>
      )}

      <FilterContainer size="select">
        <CountryFilter />
      </FilterContainer>

      {showContractTypeFilter && (
        <FilterContainer size="select">
          <ContractTypeFilter />
        </FilterContainer>
      )}

      <FilterContainer size="select">
        <InvoiceStatusFilter />
      </FilterContainer>

      {!hideBookSelect && (
        <FilterContainer size="select">
          <BookFilter />
        </FilterContainer>
      )}

      <FilterContainer size="select">
        <BuyerFilter />
      </FilterContainer>

      <FilterContainer size="select">
        <CardIFilter />
      </FilterContainer>

      <FilterContainer size="select">
        <ContractNbFilter />
      </FilterContainer>

      <FilterContainer size="select">
        <SourceFilter />
      </FilterContainer>

      <FilterContainer size="fit">
        <PreliminaryToggleFilter />
      </FilterContainer>

      <FilterContainer size="fit">
        <RegulToggleFilter />
      </FilterContainer>

      <FilterContainer size="fit">
        <CancelledToggleFilter />
      </FilterContainer>
    </Filters>
  );
};

InvoiceFilters.defaultProps = {
  hideBookSelect: false,
};
