import React from 'react';
import { Button } from '@GDM/Button';
import { Checkbox, Input } from '@GDM/forms';
import { cve_sso_path, engie_sso_path, urbasolar_sso_path, eurowatt_sso_path } from '@utils/routes';
import classNames from 'classnames';

const LoginForm = ({
  authenticity_token,
  sso_source,
}: {
  authenticity_token: string;
  sso_source?: 'engie' | 'urbasolar' | 'eurowatt' | 'cve';
}) => {
  let sso_url = null;
  let sso_button_text = null;
  if (sso_source == 'engie') {
    sso_url = engie_sso_path();
    sso_button_text = 'common.log_in_okta';
  }
  if (sso_source == 'urbasolar') {
    sso_url = urbasolar_sso_path();
    sso_button_text = 'common.log_in_azure';
  }
  if (sso_source == 'eurowatt') {
    sso_url = eurowatt_sso_path();
    sso_button_text = 'common.log_in_azure';
  }
  if (sso_source == 'cve') {
    sso_url = cve_sso_path();
    sso_button_text = 'common.log_in_azure';
  }

  return (
    <form noValidate={true} action={sso_url ?? '/users/sign_in'} acceptCharset="UTF-8" method="post">
      <input type="hidden" value={authenticity_token} name="authenticity_token" />
      <input name="utf8" type="hidden" value="✓" />
      {!sso_url && (
        <>
          <Input full size="lg" type="email" placeholder="common.email" name="user[email]" />
          <Input
            className="mt-3"
            full
            size="lg"
            type="password"
            placeholder="common.password"
            name="user[password]"
            autoComplete="on"
          />

          <div className="ta:left">
            <Checkbox className="my-3" name="user[remember_me]" label="common.remember_me" />
          </div>
        </>
      )}

      <Button
        className={classNames({ 'mt-5': !sso_url })}
        name="commit"
        type="submit"
        value="Connect"
        size="sm"
        full
        variant="primary-1"
        text={sso_button_text ?? 'common.log_in'}
      />
    </form>
  );
};

export default LoginForm;
