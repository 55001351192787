import React, { InputHTMLAttributes } from 'react';
import { Input, InputProps } from '@GDM/forms';
import { FieldValues, FieldPath, useController, UseControllerProps } from 'react-hook-form';

export type ControlledInputProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = Omit<
  InputProps,
  'value' | 'onChange'
> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> &
  UseControllerProps<TFieldValues, TFieldName>;

export default function ControlledInput<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...inputProps
}: ControlledInputProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    disabled: inputProps.disabled,
  });

  return (
    <Input
      {...inputProps}
      onChange={(event) => field.onChange(event.target.value)}
      value={field.value}
      hasError={Boolean(fieldState.error)}
      errorMessage={fieldState.error?.message}
    />
  );
}
