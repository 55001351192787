import { appendColon } from '@utils/string';
import { Locale } from '@utils/types/common-types';
import { Timezone } from '@utils/types/countries';

const labelStyle = {
  color: 'var(--grey)',
  fontWeight: '400',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
};

export const getDefaultOptions = (timezone: Timezone, locale?: Locale, isLoading?: boolean): Highcharts.Options => ({
  chart: {
    type: 'column',
    zoomType: 'x',
    height: '450px',
    style: {
      fontFamily: "'Nunito sans', sans-serif'",
      color: 'var(--grey)',
    },
    resetZoomButton: {
      position: {
        y: 42,
      },
      theme: {
        r: 4,
        fill: 'var(--primary-2)',
        stroke: 'none',
        style: {
          fontFamily: 'Nunito sans',
          borderColor: '1px solid var(--grey)',
          backgroundColor: 'var(--primary-2)',
          padding: '1rem',
          fontWeight: '700',
          fontSize: '12px',
          transitionDuration: '0.2s',
          color: 'var(--bg)',
        },
        states: {
          hover: {
            fill: 'var(--primary-2-alpha)',
          },
        },
      },
    },
  },
  colors: [
    'var(--chart-aqua)',
    'var(--chart-grey)',
    'var(--chart-yellow)',
    'var(--chart-blue)',
    'var(--chart-orange)',
    'var(--chart-purple)',
    'var(--chart-deep-purple)',
    'var(--chart-primary-2)',
    'var(--primary-1)',
  ],
  time: {
    timezone,
  },
  title: {
    text: '',
    style: labelStyle,
  },
  exporting: { enabled: false },
  navigator: { enabled: false },
  scrollbar: { enabled: false },
  rangeSelector: { enabled: false },
  credits: { enabled: false },
  legend: {
    align: 'right',
    verticalAlign: 'top',
  },
  noData: {
    style: {
      display: isLoading ? 'none' : 'initial',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      second: '%Y-%m-%d %H:%M',
      minute: '%Y-%m-%d %H:%M',
      hour: '%H:%M',
      day: '%d %b (%a)',
      month: '%Y-%m',
    },
  },
  yAxis: { opposite: true },
  tooltip: {
    dateTimeLabelFormats: {
      second: '%Y-%m-%d %H:%M',
      minute: '%Y-%m-%d %H:%M',
      hour: '%Y-%m-%d %H:%M',
      day: '%d %b (%a)',
      month: '%Y-%m',
    },
    nullFormat: '0',
    pointFormatter() {
      return `<span style="color: ${this.color}">●</span> ${appendColon(
        this.series.name,
        locale,
      )} <b>${new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(this.y ?? 0)} ${
        this.series.options?.tooltip?.valueSuffix ?? ''
      }</b><br/>`;
    },
    shared: true,
    split: false,
    borderWidth: 2,
    borderColor: 'var(--table-border)',
    borderRadius: 4,
    shadow: false,
    backgroundColor: 'var(--bg)',
    valueDecimals: 2,
  },
  plotOptions: {
    column: {
      maxPointWidth: 50,
    },
    series: {
      marker: { radius: 0 },
    },
  },
});
