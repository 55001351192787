import React from 'react';
import { Alert } from '@GDM/Alert';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import { RequestError } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import styles from './form-errors.module.scss';
import { FormRequestError } from './form-errors.types';

export const FormErrors = ({
  errors,
  title,
}: {
  errors: FormRequestError[] | RequestError[] | null | undefined;
  title: string;
}) => {
  const { t } = useTranslation();

  const printError = (error?: FormRequestError) => {
    const errorI18nString = error?.code ? ERRORS_TO_REMAP[error.code] || error.code : 'errors.unknown_error';

    return t(errorI18nString, {
      prop: `<code>${error?.field}</code>`,
      date: error?.date,
      count: error?.count,
    });
  };

  if (!errors?.length) {
    return null;
  }

  return (
    <Alert variant="danger" className={styles.alert} dismissible>
      <div className={styles.title}>{t(title)}</div>
      <ul className={styles['error-list']}>
        {errors.map((error) => (
          <li key={JSON.stringify(error)} dangerouslySetInnerHTML={{ __html: printError(error) }} />
        ))}
      </ul>
    </Alert>
  );
};
