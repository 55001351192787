import React, { useState } from 'react';
import { Tab, Tabs } from '@GDM/Tabs/Tabs';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { Counterparts } from './Counterparts';
import { RiskAnalysisSensitivity } from './Sensitivity';
import { RiskAnalysisReportType } from './types';
import { RiskAnalysisValuation } from './Valuation';

const TABS: Tab<RiskAnalysisReportType>[] = [
  { id: 'valuation', name: 'risk_analysis.valuation' },
  { id: 'sensitivity', name: 'risk_analysis.sensitivity' },
  { id: 'counterparts', name: 'risk_analysis.counterparts' },
];

const RiskAnalysis = ({ user }: { user: User }) => {
  const [currentTab, setCurrentTab] = useState<RiskAnalysisReportType>('valuation');

  return (
    <Page title="risk_analysis.risk_analysis" user={user}>
      <Tabs tabs={TABS} onTabChange={setCurrentTab}>
        {currentTab === 'valuation' && <RiskAnalysisValuation />}
        {currentTab === 'sensitivity' && <RiskAnalysisSensitivity />}
        {currentTab === 'counterparts' && <Counterparts />}
      </Tabs>
    </Page>
  );
};

export default RiskAnalysis;
