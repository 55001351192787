export const ENERGY_TYPES = ['wind', 'solar', 'hydro', 'book', 'other'] as const;

export type EnergyType = typeof ENERGY_TYPES[number];

export type Option<T = string> = {
  label: string;
  value: T;
  defaultChecked?: boolean;
  disabled?: true;
  isItalic?: boolean;
};
export type GroupedOption<T = string, TOption = Option<T>> = {
  label: string;
  options: TOption[];
};

export type Locale = 'fr' | 'en' | 'es' | 'it' | 'pl' | 'pt';
export type Primitive = null | undefined | string | number | boolean | symbol | bigint;
export type DestroyAttribute = { _destroy?: string };

// This type exist because in html, input with no value is an empty string (and not null/false/undefined...etc)
export type TypeToInputValue<T> = T | '';

export type StreemModule = 'pricing' | 'monitoring' | 'salesfocus';
