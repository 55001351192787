import React, { useEffect } from 'react';
import { Alert } from '@GDM/Alert';
import { FormErrors } from '@GDM/FormErrors';
import { FormRequestError } from '@GDM/FormErrors/form-errors.types';
import { UseMutationResult } from '@tanstack/react-query';
import { ContractFormBody } from '@utils/types/contract';
import { ContractFormMode } from '../hooks/useContractForm/types';

export const FormAlerts = ({
  mode,
  submitMutation,
}: {
  mode: ContractFormMode;
  submitMutation: UseMutationResult<Response, Error, ContractFormBody>;
}) => {
  const [data, setData] = React.useState<{ errors?: FormRequestError[] } | null>(null);

  useEffect(() => {
    const loadData = async () => {
      if (submitMutation.data) {
        const newData = await submitMutation.data.json();

        setData(newData);
      }
    };

    loadData();
  }, [submitMutation.data]);

  if (submitMutation.isSuccess && !data?.errors?.length)
    return (
      <Alert
        icon="Info"
        label={
          mode === 'edit'
            ? 'sales_management.info.contract_successfully_updated'
            : 'sales_management.info.contract_successfully_created'
        }
        dismissible
      />
    );

  if (data?.errors?.length) {
    return <FormErrors errors={data.errors} title="sales_management.form_request_has_errors" />;
  }

  return null;
};
