import { tString } from '@hooks/useTranslation';
import jsonToCsv from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import getCommonFields from './commonFields';

const indexCsvExport = (filteredInvoices: MeterInvoice[], locale: Locale): string => {
  const t = tString(locale);

  return jsonToCsv(filteredInvoices, [
    ...getCommonFields(locale),
    { label: t('sales_management.buyer'), key: 'contract_buyer_short_name' },
    { label: t('sales_management.first_index'), key: 'volume_first_index' },
    { label: t('sales_management.last_index'), key: 'volume_last_index' },
    { label: t('sales_management.first_consumption_index'), key: 'volume_first_conso_index' },
    { label: t('sales_management.last_consumption_index'), key: 'volume_last_conso_index' },
  ]);
};

export default indexCsvExport;
