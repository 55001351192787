import React from 'react';
import { SingleSelectProps, MultiSelectProps } from '@GDM/forms';
import { Select } from '@GDM/forms/Select/Select';
import { Option } from '@utils/types/common-types';
import { FieldValues, PathValue, FieldPath, useController, UseControllerProps } from 'react-hook-form';

export type ControlledSelectProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = Omit<
  | SingleSelectProps<PathValue<TFieldValues, TFieldName>>
  | MultiSelectProps<PathValue<TFieldValues, TFieldName>[number]>,
  'onChange' | 'selectedOptions' | 'selectedOption'
> &
  UseControllerProps<TFieldValues, TFieldName>;

export default function ControlledSelect<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  name,
  control,
  options,
  rules,
  shouldUnregister,
  defaultValue,
  components,
  ...selectProps
}: ControlledSelectProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    disabled: selectProps.disabled,
  });

  return selectProps.isMulti ? (
    <Select
      {...selectProps}
      isMulti
      name={field.name}
      options={options}
      onChange={(option: Option<PathValue<TFieldValues, TFieldName>>[] | null) => {
        const value = option?.map(({ value }) => value);
        field.onChange(value ?? null);
      }}
      selectedOption={undefined}
      selectedOptions={field.value}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
    />
  ) : (
    <Select
      {...selectProps}
      isMulti={false}
      name={field.name}
      options={options}
      isDisabled={selectProps.disabled}
      onChange={(option: Option<PathValue<TFieldValues, TFieldName>> | null) => {
        const value = option?.value;
        field.onChange(value ?? null);
      }}
      selectedOption={field.value}
      selectedOptions={undefined}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
    />
  );
}
