import { useEffect, useRef } from 'react';
import { formatSystemDate } from '@utils/formatters';
import {
  v2_book_invoices_path,
  v2_installation_invoices_path,
  v2_market_player_invoices_path,
  v2_meter_invoices_path,
} from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import { useRequest } from '../../useRequest';

type SourceType = 'meter' | 'book' | 'installation' | 'market_player';

const getPath = (source_type: SourceType) => {
  if (source_type === 'meter') {
    return v2_meter_invoices_path;
  }

  if (source_type === 'installation') {
    return v2_installation_invoices_path;
  }

  if (source_type === 'book') {
    return v2_book_invoices_path;
  }

  if (source_type === 'market_player') {
    return v2_market_player_invoices_path;
  }

  throw new Error('Invalid source type');
};

export const useInvoicesByIdentifier = (opts: {
  identifier: string;
  source_type: SourceType;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  const { identifier, source_type, startDate, endDate } = opts;
  const abortController = useRef(new AbortController());
  const request = useRequest<{ invoices: MeterInvoice[] }>(
    getPath(source_type)(identifier, {
      start_date: startDate ? formatSystemDate(startDate) : undefined,
      end_date: endDate ? formatSystemDate(dayjs(endDate).add(1, 'd').toDate()) : undefined,
    }),
    'GET',
    !endDate,
    abortController.current,
  );

  useEffect(() => {
    const abortControllerRef = abortController.current;

    return () => {
      abortControllerRef.abort();
    };
  }, []);

  return { ...request, data: request.data?.invoices || [] };
};
