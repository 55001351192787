import React, { useState } from 'react';
import { RiskAnalysisConfiguration } from './Configuration';
import { RiskAnalysisReport } from './Report/Report';
import { GreenstarReport } from './types';

export const RiskAnalysisSensitivity = () => {
  const [report, setReport] = useState<GreenstarReport>();

  return (
    <div className="p-4">
      <RiskAnalysisConfiguration onReportGenerate={setReport} type="sensitivity" />
      {report && <RiskAnalysisReport report={report} type="sensitivity" />}
    </div>
  );
};
