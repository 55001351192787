import React, { useMemo } from 'react';
import { sectionStyles } from '@GDM/forms';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { ContractSubPeriod } from '@utils/types/contract/sub-period';
import { ContractType } from '@utils/types/contract/unions';
import { CountryCode } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DEFAULT_PRICE_RULES_OPTIONS, NEG_PRICE_RULE_CONTRACT_OPTIONS_MAP } from '../../../constants/negPriceRules';
import styles from '../../../sub-period.module.scss';
import { AggregatorFees } from './AggregatorFees/AggregatorFees';
import { IndexationSettings } from './IndexationSettings';

const CONTRACT_TYPES_WITH_DOUBLE_AGGREG_FEES: ContractType[] = ['ContractCr', 'ContractSoa', 'ContractCppa'];

export const SubPeriodPricing = ({
  index,
  form,
  readOnly,
  priceRules,
  currency,
  hasAggregatorFees,
  hasNegativePrices,
  hasIndexFormula,
  hasPriceRules,
  contractType,
  country,
  indexFormulas,
}: {
  form: UseFormReturn<ContractForm>;
  index: number;
  readOnly: boolean;
  priceRules: ContractFormQueries['priceRules'];
  currency: Currency;
  hasAggregatorFees?: boolean;
  hasNegativePrices?: boolean;
  hasPriceRules?: boolean;
  hasIndexFormula?: boolean;
  country: CountryCode | undefined | null;
  contractType: ContractType;
  indexFormulas: ContractFormQueries['indexFormulas'];
}) => {
  const { control, watch } = form;
  const [priceType, priceRuleId] = watch([
    `contract_sub_periods_attributes.${index}.price_type`,
    `contract_sub_periods_attributes.${index}.price_rule.id`,
  ]);

  const priceIsIndexed = priceType === 'indexed';
  const priceRuleIsDisabled = priceIsIndexed;

  const priceTypesWithNoPriceInput: ContractSubPeriod['price_type'][] = ['spot', 'imbalance_pos', 'prefcapa'];
  const priceTypeHasNoPriceInput = priceTypesWithNoPriceInput.includes(priceType);
  const priceInputIsDisabled = priceTypeHasNoPriceInput;

  const negativePriceRulesOptions =
    NEG_PRICE_RULE_CONTRACT_OPTIONS_MAP[contractType || ''] || DEFAULT_PRICE_RULES_OPTIONS;

  const priceRulesOptions: Option<string | null>[] = useMemo(
    () => [
      { value: null, label: 'common.none' },
      ...(priceRules.data?.map((rule) => ({
        value: rule.id,
        label: rule.name,
      })) || []),
    ],
    [priceRules],
  );

  const canHaveMultipleFees = CONTRACT_TYPES_WITH_DOUBLE_AGGREG_FEES.includes(contractType);

  return (
    <div className={styles.section}>
      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        <NumericInput
          id="DisplayPrice"
          control={control}
          name={`contract_sub_periods_attributes.${index}.display_price`}
          label="common.price"
          size="lg"
          autoComplete="off"
          readOnly={readOnly}
          full
          data-cy={`display-price-${index}`}
          disabled={priceInputIsDisabled}
          suffix={`${getSymbol(currency)}/MWh`}
        />
      </div>

      {hasAggregatorFees && (
        <AggregatorFees
          form={form}
          name={`contract_sub_periods_attributes.${index}.aggreg_fees`}
          currency={currency}
          readOnly={readOnly}
          isMulti={canHaveMultipleFees}
        />
      )}
      {hasNegativePrices && (
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          <div className="wider">
            <ListInput
              id="NegPriceRulePicker"
              control={control}
              name={`contract_sub_periods_attributes.${index}.neg_price_rule`}
              label="sales_management.neg_price_rule"
              options={negativePriceRulesOptions}
              readOnly={readOnly}
              menuPlacement="top"
              classNamePrefix="select-neg-price-rule"
              disabled={Boolean(priceRuleId)}
            />
          </div>
        </div>
      )}
      {hasPriceRules && (
        <div className={classNames(sectionStyles.row, 'mt-4')}>
          <div className={classNames(styles['select-container'], 'wider')}>
            <ListInput
              id="PriceRulePicker"
              control={control}
              name={`contract_sub_periods_attributes.${index}.price_rule.id`}
              label="sales_management.price_rule"
              options={priceRulesOptions}
              readOnly={readOnly}
              menuPlacement="top"
              isLoading={priceRules.isLoading}
              disabled={priceRuleIsDisabled}
            />
          </div>
        </div>
      )}
      {hasIndexFormula && (priceIsIndexed || country === 'UK') && (
        <IndexationSettings form={form} indexFormulas={indexFormulas} contractType={contractType} readOnly={readOnly} />
      )}
    </div>
  );
};
