import { tString } from '@hooks/useTranslation';
import jsonToCsv from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import getCommonFields from './commonFields';

const loadCurveCsvExport = (filteredInvoices: MeterInvoice[], locale: Locale): string => {
  const t = tString(locale);

  return jsonToCsv(filteredInvoices, [
    ...getCommonFields(locale),
    { label: t('sales_management.buyer'), key: 'contract_buyer_short_name' },
    { label: t('monitoring.invoicing.delta_index'), key: 'volume_delta_prod_vs_index' },
    { label: t('monitoring.invoicing.raw_curve'), key: 'volume_delta_prod_vs_load_curve' },
    { label: t('monitoring.invoicing.clipping'), key: 'volume_clipped' },
    { label: t('monitoring.invoicing.negative_price'), key: 'volume_produced_at_neg_prices' },
    { label: t('monitoring.invoicing.dispo_enedis'), key: 'volume_coverage' },
    { label: t('monitoring.invoicing.dispo_enedis_raw'), key: 'volume_raw_coverage' },
    { label: t('monitoring.invoicing.invoicing_type'), key: 'invoicing_type' },
    {
      label: t('monitoring.invoicing.report'),
      key: 'deferred_status',
      transform: (v) => (v == 'deferred' || v == 'partially_deferred' ? 'yes' : ''),
    },
  ]);
};

export default loadCurveCsvExport;
