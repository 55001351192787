import React from 'react';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { styles } from '@GDM/Filters';
import fetchWrapper from '@utils/fetch';
import { v2_green_star_counter_parts_path } from '@utils/routes';
import { useForm } from 'react-hook-form';
import { useGreenStarGroups } from './queries';

export const Counterparts = () => {
  const { control, handleSubmit } = useForm();

  const groupsQuery = useGreenStarGroups();

  const grappeOptions =
    groupsQuery.data?.grappes.map((grappe) => ({
      value: grappe,
      label: grappe,
    })) || [];

  const holdingOptions =
    groupsQuery.data?.holdings.map((holding) => ({
      value: holding,
      label: holding,
    })) || [];

  const spvOptions =
    groupsQuery.data?.spvs.map((spv) => ({
      value: spv,
      label: spv,
    })) || [];

  const onSubmit = async () => {
    const response = await fetchWrapper(v2_green_star_counter_parts_path());
    const data = await response.json();

    return data;
  };

  return (
    <form className="d-flex justify-content-between p-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex gap-4">
        <ControlledSelect
          isMulti
          name="spv"
          control={control}
          options={spvOptions}
          placeholder="sales_management.owner"
          className={styles.select}
        />
        <ControlledSelect
          isMulti
          name="grappe"
          control={control}
          options={grappeOptions}
          placeholder="risk_analysis.grappe"
          className={styles.select}
        />
        <ControlledSelect
          isMulti
          name="holding"
          control={control}
          options={holdingOptions}
          placeholder="risk_analysis.holding"
          className={styles.select}
        />
        <ControlledSelect
          name="apply_ownership_percentage"
          control={control}
          options={[
            { label: '100 %', value: false },
            { label: 'risk_analysis.consolidated', value: true },
          ]}
          placeholder="risk_analysis.vision"
          className={styles.select}
          defaultValue={false}
        />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />
        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
        />
      </div>
    </form>
  );
};
