import { useMemo } from 'react';
import type { DataProvider } from '@api/dataProviders';
import { useInternalFormContext } from '@GDM/forms';
import { useDataProviders } from '@hooks/requests/useDataProvider';
import { useGrids } from '@hooks/requests/useGrids';
import type { UseFormWatch } from 'react-hook-form';
import type { InstallationForm } from '../installation.types';

export const useFormDataProviders = (watch: UseFormWatch<InstallationForm>) => {
  const selectedGridId = watch('grid_id');
  const { country } = useInternalFormContext();
  const grids = useGrids(country);
  const dataProviders = useDataProviders(country || 'FR');
  const selectedGrid = grids.data?.find(({ id }) => id === selectedGridId);

  const allDataProviders = useMemo(() => {
    const gridProviders = selectedGrid?.data_providers || [];
    const otherProviders = dataProviders.data || [];
    const allProviders = [...gridProviders, ...otherProviders];
    const uniqueProvidersIds = Array.from(new Set(allProviders.map(({ id }) => id)));

    return uniqueProvidersIds
      .map((id) => allProviders.find(({ id: providerId }) => providerId === id))
      .filter((dataProvider): dataProvider is DataProvider => Boolean(dataProvider));
  }, [selectedGrid?.data_providers, dataProviders.data]);

  return allDataProviders;
};
