import { useCallback, useEffect, useState } from 'react';
import { contractFiltersDefaultValues, ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { RequestError, useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { all_v2_contracts_path, v2_contract_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { AxiosError } from 'axios';
import { filterContracts } from './utils/filterContracts';
import { findAndReplaceContracts } from './utils/findAndReplaceContracts';
import { sortContractsByStartDate } from './utils/sortContractsByStartDate';

const deleteContract = (url: string) =>
  axiosInstance(false)
    .delete<Contract[]>(url, { headers: { accept: 'application/json' } })
    .then((response) => response.data);

const useContracts = (options?: {
  bookId?: string;
  meterId?: string;
}): {
  data: Contract[];
  allContracts: Contract[];
  addContract: (contract: Contract) => void;
  removeContract: (contract: Contract) => Promise<true | undefined>;
  filterContracts: (filters: ContractsFiltersType) => void;
  replaceContracts: (contracts: Contract[]) => void;
  updateContract: (contract_id: string, newContract: Contract) => void;
  loading: boolean;
  loaded: boolean;
  error: RequestError | null;
  execute?: () => void;
  isDeleting: boolean;
  deleteError: string | null;
  resetDeleteError: () => void;
} => {
  const [allContracts, setAllContracts] = useState<Contract[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const params = new URLSearchParams(options).toString();
  const url = `${all_v2_contracts_path()}${params ? '?' : ''}${params}`.trim();
  const req = useRequest<Contract[]>(url, 'GET');

  const addContract = useCallback((contract: Contract) => {
    setContracts((_contracts) => {
      const index = _contracts.findIndex((c) => c.id === contract.id);

      if (index !== -1) {
        const newContracts = [..._contracts];

        newContracts[index] = contract;

        return newContracts.sort(sortContractsByStartDate);
      } else {
        return [..._contracts, contract].sort(sortContractsByStartDate);
      }
    });
  }, []);

  const destroyContract = async (id: string) => {
    setIsDeleting(true);
    setDeleteError(null);
    let hasError = false;

    try {
      await deleteContract(v2_contract_path(id));
    } catch (e) {
      setDeleteError(((e as AxiosError).response?.data as { code: string }).code);
      hasError = true;
    }

    setIsDeleting(false);

    return hasError;
  };

  const removeContract = useCallback(
    async (contract: Contract) => {
      const contractIndex = contracts.findIndex((c) => c.id === contract.id);

      if (contractIndex !== -1) {
        const hasError = await destroyContract(contract.id);

        if (!hasError) {
          setContracts((currentContracts) => {
            const clonedContracts = [...currentContracts];

            clonedContracts.splice(contractIndex, 1);

            return clonedContracts;
          });
        } else {
          return hasError;
        }
      }
    },
    [contracts],
  );

  const updateContract = useCallback((contract_id: string, newContract: Contract) => {
    setContracts((_contracts) => {
      const index = _contracts.findIndex((c) => c.id === contract_id);

      if (index !== -1) {
        const newContracts = [..._contracts];
        newContracts[index] = newContract;

        return newContracts;
      }

      return _contracts;
    });

    setAllContracts((_contracts) => {
      const index = _contracts.findIndex((c) => c.id === contract_id);

      if (index !== -1) {
        const newContracts = [..._contracts];
        newContracts[index] = newContract;

        return newContracts;
      }

      return _contracts;
    });
  }, []);

  const internalFilterContracts = useCallback(
    (filters: ContractsFiltersType) => {
      setContracts(() => filterContracts(filters, allContracts));
    },
    [allContracts],
  );

  const replaceContracts = useCallback((contracts: Contract[]) => {
    setContracts(findAndReplaceContracts(contracts));
    setAllContracts(findAndReplaceContracts(contracts));
  }, []);

  useEffect(() => {
    if (req?.data) {
      setContracts(filterContracts(contractFiltersDefaultValues, req.data));
      setAllContracts(req.data);
    }
  }, [req.data]);

  return {
    ...req,
    data: contracts,
    allContracts,
    addContract,
    removeContract,
    filterContracts: internalFilterContracts,
    replaceContracts,
    updateContract,
    isDeleting,
    deleteError,
    resetDeleteError: () => setDeleteError(null),
  };
};

export default useContracts;
