import React from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { Button } from '@GDM/Button/Button';
import { Chart } from '@GDM/Chart/Chart';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import { UseFormReturn, useForm } from 'react-hook-form';
import styles from '../styles.module.scss';
import { GreenstarReport, RiskAnalysisReportType } from '../types';
import { useRiskAnalysisCharts } from '../useCharts';
import { useRiskAnalysisTable } from './hooks/table';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReport = ({
  report: { data: rawData, filters, exportUrl },
  type,
}: {
  report: GreenstarReport;
  type: RiskAnalysisReportType;
}) => {
  const { control, watch } = useForm();
  const display = watch('display', 'table');
  const splitByMetric = watch('splitByMetric', false);
  const isReportNested = Boolean(filters.secondaryFocus) || type === 'sensitivity';

  const data = useFormatReportData(rawData, isReportNested, splitByMetric);
  const { columns, rows } = useRiskAnalysisTable(data, isReportNested);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  console.log(data, 'data orginal');
  const charts = useRiskAnalysisCharts({ data, isReportNested, splitByMetric });
  console.log(charts, 'chart data');

  return (
    <div className="mt-4">
      {type === 'sensitivity' && <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />}
      {type === 'valuation' && <RiskAnalysisValuationReportHeader control={control} exportUrl={exportUrl} />}
      <div className="mt-4">
        {display === 'table' && (
          <Table>
            <TableHead table={table} />
            <TableBody table={table} />
          </Table>
        )}
        {display === 'chart' && (
          <div className={styles['chart-grid']}>
            {charts.map((chart) => (
              <Chart {...chart} key={chart.key} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: UseFormReturn['control'];
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox className="mr-2" name="volume" label="Volume" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="open_volume" label="Open Volume" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="clicked_volume"
          label="Clicked Volume"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="hcr" label="HCR" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="mtom" label="MtoM" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="net_price" label="Net Price" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="subsidy_price" label="OA/CfD Price" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="float_price" label="Float Price" control={control} defaultValue />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />
        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};

const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: UseFormReturn['control'];
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox
          className="mr-2"
          name="price_outright"
          label="Price Outright"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="profile_risk" label="Profile Risk" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="balancing_risk"
          label="Balancing Risk"
          control={control}
          defaultValue
        />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />

        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};
