import React from 'react';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

export const PreliminaryToggleFilter = () => {
  const form = useInvoicesFilters();

  return (
    <ControlledToggle
      control={form.control}
      name="forecast_type"
      label="common.filters.show_preliminary"
      data-cy="invoice-filters-preliminary-toggle"
      variant="primary-2"
    />
  );
};
