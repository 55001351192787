import { useMemo } from 'react';
import useTranslation, { type TranslateFn } from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import type { ContractForm } from '@utils/types/contract';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import type Installation from '@utils/types/installation';
import type MarketPlayer from '@utils/types/market_player';
import type { User } from '@utils/types/user';

export type OptionWithMarketPlayerType = Option<string> & { type?: MarketPlayer['player_type'] };

export const useBuySellOptions = (
  accountType: User['account_type'],
  direction: ContractForm['direction'],
  marketPlayers: MarketPlayer[],
  intermediaries: MarketPlayer[],
  installation: Installation | null,
): {
  buyerOptions: OptionWithMarketPlayerType[];
  sellerOptions: OptionWithMarketPlayerType[];
} => {
  const { t } = useTranslation();

  return useMemo(() => {
    return getFilteredOptions(accountType, direction, intermediaries, marketPlayers, installation, t);
  }, [accountType, direction, intermediaries, marketPlayers, installation, t]);
};

type GetOptions = (
  accountType: User['account_type'],
  direction: ContractForm['direction'],
  allMarketPlayersOptions: OptionWithMarketPlayerType[],
  intermediariesOptions: OptionWithMarketPlayerType[],
  installationOwnerOption: Option<string> | null,
) => OptionWithMarketPlayerType[];

export const getBuyers: GetOptions = (
  accountType,
  direction,
  allMarketPlayersOptions,
  intermediariesOptions,
  installationOwnerOption,
) => {
  if (accountType === 'aggregator' && direction === 'buy') {
    return [...intermediariesOptions, ...allMarketPlayersOptions.filter(({ type }) => type === 'offtaker')];
  }

  if (accountType === 'producer' && direction === 'buy') {
    return [installationOwnerOption, ...intermediariesOptions].filter(filterOutNullOption);
  }

  if (accountType === 'producer' && direction === 'sell') {
    return [...intermediariesOptions, ...allMarketPlayersOptions];
  }

  return [];
};

export const getSellers: GetOptions = (
  accountType,
  direction,
  allMarketPlayersOptions,
  intermediariesOptions,
  installationOwnerOption,
) => {
  if (accountType === 'aggregator' && direction === 'buy') {
    return [installationOwnerOption, ...intermediariesOptions].filter(filterOutNullOption);
  }

  if (accountType === 'producer' && direction === 'buy') {
    return [...intermediariesOptions, ...allMarketPlayersOptions];
  }

  if (accountType === 'producer' && direction === 'sell') {
    return [installationOwnerOption, ...intermediariesOptions].filter(filterOutNullOption);
  }

  return [];
};

const marketPlayerToOption = (marketPlayer: MarketPlayer) => ({
  label: marketPlayer.short_name,
  value: marketPlayer.id,
  type: marketPlayer.player_type,
});

const filterOutNullOption = (value: OptionWithMarketPlayerType | null): value is OptionWithMarketPlayerType =>
  Boolean(value);

export const getDefaultValue = (
  options: OptionWithMarketPlayerType[],
  offtaker: MarketPlayer | null,
  installation: InstallationWithMeterInfo | null,
) => {
  if (options.length === 1) {
    return options[0].value;
  }

  if (offtaker && options.find(({ value }) => value === offtaker.id)) {
    return offtaker.id;
  }

  if (installation && options.find(({ value }) => value === installation.owner_uuid)) {
    return installation.owner_uuid;
  }

  return null;
};

export const getBaseOptions = (
  intermediaries: MarketPlayer[],
  marketPlayers: MarketPlayer[],
  installation: Installation | null,
  t: TranslateFn,
) => {
  const installationCountry = installation?.country || 'FR';

  const intermediariesOptions = intermediaries.map(marketPlayerToOption);
  const allMarketPlayersOptions = marketPlayers
    .filter(
      (marketPlayer) =>
        marketPlayer.country === installationCountry || marketPlayer.operating_countries?.includes(installationCountry),
    )
    .map(marketPlayerToOption);

  const installationOwnerOption =
    installation && installation.owner_uuid
      ? {
          label: `${installation?.owner_short_name || ''} (${t('sales_management.owner')})`.trim(),
          value: installation.owner_uuid,
        }
      : null;

  return {
    intermediariesOptions,
    allMarketPlayersOptions,
    installationOwnerOption,
  };
};

export const getFilteredOptions = (
  accountType: User['account_type'],
  direction: ContractForm['direction'],
  intermediaries: MarketPlayer[],
  marketPlayers: MarketPlayer[],
  installation: Installation | null,
  t: TranslateFn,
) => {
  const { intermediariesOptions, allMarketPlayersOptions, installationOwnerOption } = getBaseOptions(
    intermediaries,
    marketPlayers,
    installation,
    t,
  );

  const buyers = getBuyers(
    accountType,
    direction,
    allMarketPlayersOptions,
    intermediariesOptions,
    installationOwnerOption,
  );

  const sellers = getSellers(
    accountType,
    direction,
    allMarketPlayersOptions,
    intermediariesOptions,
    installationOwnerOption,
  );

  return {
    buyerOptions: buyers.sort(sortOptionsByLabelAsc),
    sellerOptions: sellers.sort(sortOptionsByLabelAsc),
  };
};
