import { MeterInvoice } from '@utils/types/meter-invoice';
import { FormForecastType } from 'pages/sales-management/invoicing/invoicesFiltersContext';

type ForecastTypeObject = {
  forecast_type: FormForecastType;
};

/**
 * This one is specific to MeterInvoice
 */
export default function isForecastType(meterInvoice: MeterInvoice, filters: ForecastTypeObject): boolean {
  const isForecast = meterInvoice.forecast || meterInvoice.volume_forecast;

  // we return everything if filters is set to forecast and only non forecast if filters is set to empty or null
  return (!filters.forecast_type && !isForecast) || filters.forecast_type === 'forecast';
}
