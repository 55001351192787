import React, { useState, useEffect } from 'react';
import { Button } from '@GDM/Button';
import { Collapse } from '@GDM/Collapse';
import { Input } from '@GDM/forms';
import { Icon } from '@GDM/Icon';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { useGenerateInvoice } from '@hooks/requests/useGenerateInvoice';
import useTranslation from '@hooks/useTranslation';
import { requiresNumbering } from '@utils/invoices/requiresNumbering';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import { useInvoicesContext } from './invoicesContext';
import Table from './InvoicesTable';
import useInvoiceNumbering from './utils/useInvoiceNumbering';

type Props = {
  invoiceType: MeterInvoiceSource;
  isOpen: boolean;
  closeModal: () => void;
};

export const InvoicesNumberingModal = ({ invoiceType, closeModal, isOpen }: Props) => {
  const { t } = useTranslation();
  const { updateInvoices, updateInvoice } = useInvoicesContext();
  const { finalAction, actionType, invoicesToNumber, setInvoicesToNumber } = useInvoiceNumbering();
  const [pageInvoiceIndex, setPageInvoiceIndex] = useState<number>(0);
  const pageInvoice = invoicesToNumber[pageInvoiceIndex];
  const [invoiceNbExpression, setInvoiceNbExpression] = useState<string>('');
  const [orderNumber, setOrderNumber] = useState('');
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const generateRequest = useGenerateInvoice({
    invoices: [{ id: pageInvoice?.uuid, invoice_nb: invoiceNbExpression, order_number: orderNumber || null }],
  });

  const isLoading = generateRequest?.loading;

  const handleIssueInvoice = () => {
    if (pageInvoice?.uuid && invoiceNbExpression) {
      if (actionType === 'generate' || actionType === 'send_email') return generateRequest.execute?.();
      if (actionType === 'nominate_to_cegedim') {
        updateInvoice({ ...pageInvoice, invoice_nb_expression: invoiceNbExpression, order_number: orderNumber });
        setInvoicesToNumber((invoicesToNumber) =>
          invoicesToNumber.filter((invoice) => invoice.uuid !== pageInvoice.uuid),
        );
        setInvoiceNbExpression('');
        setOrderNumber('');
      }
    }
  };

  useEffect(() => {
    if (!generateRequest.loading && generateRequest.data) {
      updateInvoices(generateRequest.data);
      const invoice = generateRequest.data[0];
      if (!requiresNumbering(invoice))
        setInvoicesToNumber((invoicesToNumber) =>
          invoicesToNumber.filter((_invoice) => _invoice.uuid !== invoice.uuid),
        );
      setInvoiceNbExpression('');
      setOrderNumber('');
    }
  }, [generateRequest.loading, generateRequest.data, updateInvoices, setInvoicesToNumber, pageInvoice?.uuid]);

  const finalCloseModal = () => {
    finalAction?.();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      size="lg"
      header={t('sales_management.invoice_tooltip_generate_final_invoice')}
      hideCancelButton
    >
      {invoicesToNumber.length === 0 && (
        <>
          <div className="text-center text-green m-4 d-flex align-items-center justify-content-center">
            <Icon name="CheckCircle" size={24} className="mb-2" />
            <p>{t('sales_management.all_invoices_numbered')}</p>
          </div>

          <div className="ta:right">
            <Button
              size="sm"
              variant="primary-2"
              onClick={finalCloseModal}
              text="common.continue"
              data-cy="number-invoice-continue-button"
            />
          </div>
        </>
      )}

      {invoicesToNumber.length > 0 && (
        <>
          <Row>
            <Col md="12">
              {t('sales_management.invoice_number_input', {
                start_date: pageInvoice?.start_date,
                contract_nb: pageInvoice?.contract_nb,
                installation_name: pageInvoice?.installation_name,
              })}
            </Col>

            <Col md="8" className="mt-3">
              <Input
                placeholder="sales_management.invoice_number"
                onChange={(e) => setInvoiceNbExpression(e.target.value)}
                data-cy="invoice-number-input"
                value={invoiceNbExpression}
                full
              />
            </Col>

            <Col md="12" className="mt-3">
              {t('sales_management.order_number_label', {
                start_date: pageInvoice?.start_date,
                contract_nb: pageInvoice?.contract_nb,
                installation_name: pageInvoice?.installation_name,
              })}
            </Col>

            <Col md="8" className="mt-3">
              <Input
                placeholder="sales_management.order_number_placeholder"
                onChange={(e) => setOrderNumber(e.target.value)}
                data-cy="invoice-order-number-input"
                value={orderNumber}
                full
              />
            </Col>

            <Col className="my-3" md="6">
              <Button size="xs" onClick={() => setTableVisible((s) => !s)} variant="primary-2">
                {t('sales_management.see_details')}
              </Button>
            </Col>

            <Col md="12">
              <Collapse isOpen={tableVisible} className={classNames(tableVisible && 'mb-3')}>
                <Table invoices={pageInvoice ? [pageInvoice] : []} invoiceType={invoiceType} isModal />
              </Collapse>
            </Col>
          </Row>

          {generateRequest?.error && (
            <Row>
              <Col>
                <div className="text-red mb-3">{t('invoice.error.number_unique')}</div>
              </Col>
            </Row>
          )}

          <div className="d-flex">
            <Button
              size="xs"
              disabled={isLoading || pageInvoiceIndex <= 0}
              onClick={() => setPageInvoiceIndex((i) => i - 1)}
              variant="outline"
            >
              <div className="d-flex align-items-end">
                <Icon name="ArrowLeft" size={16} className="mr-2" />
                <div>{t('sales_management.prev_invoice')}</div>
              </div>
            </Button>

            <Button
              size="xs"
              disabled={isLoading || pageInvoiceIndex >= invoicesToNumber.length - 1}
              onClick={() => setPageInvoiceIndex((i) => i + 1)}
              variant="outline"
              className="mx-3"
            >
              <div className="d-flex align-items-end">
                <div>{t('sales_management.next_invoice')}</div>
                <Icon name="ArrowRight" size={16} className="ml-2" />
              </div>
            </Button>

            <Button
              size="xs"
              variant="primary-2"
              disabled={isLoading || !invoiceNbExpression}
              onClick={handleIssueInvoice}
              isLoading={isLoading}
              text="sales_management.invoice_tooltip_generate_final_invoice"
              data-cy="number-invoice-button"
            />
          </div>
        </>
      )}
    </Modal>
  );
};
