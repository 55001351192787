import React, { useEffect, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { useRequest } from '@hooks/useRequest';
import {
  api_v1_launchtr_addtroperation_website_path,
  v2_book_load_curves_path,
  v2_installation_load_curves_path,
  v2_market_player_load_curves_path,
} from '@utils/routes';
import type Book from '@utils/types/book';
import { DataPeriod } from '@utils/types/dataPeriod';
import type MarketPlayer from '@utils/types/market_player';
import type { Meter } from '@utils/types/meter';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

export const Actions = ({
  startDate,
  endDate,
  meter,
  book,
  marketPlayer,
  dataPeriod,
  identifier,
}: {
  startDate: Date | null;
  endDate: Date | null;
  meter?: Meter;
  book?: Book;
  marketPlayer?: MarketPlayer;
  dataPeriod: DataPeriod;
  identifier: string;
}) => {
  const [launchTrAlreadyDone, setLaunchTrAlreadyDone] = useState<boolean>(false);
  const { launchtr } = useUser();
  const launchTelereleveAllowed = Boolean(launchtr && !book);

  const parsedStartDate = dayjs(startDate).format('YYYY-MM-DD HH:mm:00');
  const parsedEndDate = dayjs(endDate).format('YYYY-MM-DD HH:mm:00');

  // Launch telereleve
  const request = useRequest(
    api_v1_launchtr_addtroperation_website_path({ params: { meter: meter?.name } }),
    'GET',
    true,
  );

  let url: ((resource_name: string, options: any) => string) | null = null;
  if (meter) url = v2_installation_load_curves_path;
  if (book) url = v2_book_load_curves_path;
  if (marketPlayer) url = v2_market_player_load_curves_path;
  const downloadButtonAllowed = !!url;

  const handleCsvClick = (): void => {
    if (url)
      window.location.href = url(identifier, {
        start: parsedStartDate,
        stop: parsedEndDate,
        group_by: ['daily', 'monthly'].includes(dataPeriod) ? dataPeriod : null,
        format: 'csv',
      });
  };

  const handleXlsClick = (): void => {
    if (url)
      window.location.href = url(identifier, {
        start: parsedStartDate,
        stop: parsedEndDate,
        group_by: ['daily', 'monthly'].includes(dataPeriod) ? dataPeriod : null,
        format: 'xls',
      });
  };

  useEffect(() => {
    setLaunchTrAlreadyDone(request.loaded);
  }, [request.loaded]);

  return (
    <div className="ml-auto d-flex gap-2">
      {launchTelereleveAllowed && (
        <>
          {request.loading && <Spinner size="sm" />}
          {request.error && <Text type="danger" text="monitoring.portfolio.launch_tr_error" size="sm" />}
          <Button
            size="xxs"
            variant="primary-2"
            onClick={() => request.execute?.()}
            disabled={launchTrAlreadyDone}
            tooltip="monitoring.portfolio.launch_tr"
            icon={launchTrAlreadyDone ? 'Check' : 'PhoneCall'}
          />
        </>
      )}

      {downloadButtonAllowed && (
        <DropDownButton label="common.download" icon="Download" size="xxs" variant="primary-2" position="right">
          <Button variant="sub-button" text="common.csv" onClick={debounce(handleCsvClick, 500)} />
          <Button variant="sub-button" text="common.xls" onClick={debounce(handleXlsClick, 500)} />
        </DropDownButton>
      )}
    </div>
  );
};
