import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const CapaInvoices = () => {
  const [date, setDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
  const req = useInvoicesByType('capa', date);

  const handleDateChange = (date: Date | null) => {
    setDate(dayjs(date).toDate());
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="capa"
      isLoading={req.isPending}
      startDate={date}
      key={(req.data ?? [])?.map((i) => i.uuid).join('')}
      yearlyDate
      onDateChange={handleDateChange}
      isDateRange={false}
    />
  );
};

export default CapaInvoices;
