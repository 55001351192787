import { useState } from 'react';
import { fetchInvoicesByType } from '@api/invoices';
import { useQuery } from '@tanstack/react-query';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';

export const useInvoicesByType = (invoiceType: MeterInvoiceSource, startDate: Date, endDate?: Date | null) => {
  const [internalDates, setInternalDates] = useState({ startDate, endDate });

  /**
   * This is a workaround around the fact that react-query will refetch if one of the parameters changes, even if enable is set to false
   * So until the endDate is either set or undefined, we will "cache" the dates
   *
   * The logic is as follows:
   * If endDate is undefined, we only need startDate, because that means endDate is not required
   * If endDate is null, it means that endDate is required and we should wait until it's set before fetching
   */
  if (startDate !== internalDates.startDate && endDate !== null) {
    setInternalDates({ startDate, endDate });
  }

  return useQuery({
    queryKey: ['invoices', invoiceType, internalDates.startDate, internalDates.endDate],
    queryFn: async () => fetchInvoicesByType(invoiceType, internalDates.startDate, internalDates.endDate),
  });
};

export const queryIsEnabled = (condition = false): boolean => !!condition;
