import React from 'react';
import { SelectInvoicesProvider } from '@context/selectInvoices.context';
import { useTabs } from '@GDM/Tabs';
import { TABS } from './invoices.constants';
import { Metrics } from './Metrics';
import CapaInvoices from './tabs/capa-invoices';
import IndexInvoices from './tabs/index-invoices';
import LoadCurveInvoices from './tabs/loadcurve-invoices';
import RecInvoices from './tabs/rec-invoices';
import YearlyRegulInvoices from './tabs/yearly-regul-invoices';

export const InvoicesTabContents = () => {
  const { currentTab } = useTabs<typeof TABS[number]['id']>();

  return (
    <SelectInvoicesProvider key={currentTab}>
      {currentTab === 'loadcurve' && <LoadCurveInvoices />}
      {currentTab === 'index' && <IndexInvoices />}
      {currentTab === 'capa' && <CapaInvoices />}
      {currentTab === 'rec' && <RecInvoices />}
      {currentTab === 'yearly_regul' && <YearlyRegulInvoices />}
      {currentTab === 'invoice_metrics' && <Metrics />}
    </SelectInvoicesProvider>
  );
};
