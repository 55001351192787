import React, { createContext, useCallback, useContext, useState } from 'react';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { requiresNumbering } from '@utils/invoices/requiresNumbering';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import { useInvoicesContext } from '../invoicesContext';
import { InvoicesNumberingModal } from '../InvoicesNumberingModal';

type ActionType = 'generate' | 'send_email' | 'nominate_to_cegedim' | 'nominate_to_edf_oa';

type InvoicesNumberingContext = {
  /**
   * Opens the numbering modal
   */
  setModalVisible: (visible: boolean) => void;
  modalVisible: boolean;

  /**
   * The action called after numbering is done
   */
  finalAction: (() => void) | null;
  setFinalAction: (action: (() => void) | null) => void | null;

  actionType: ActionType | null;
  setActionType: React.Dispatch<ActionType>;

  invoicesToNumber: MeterInvoice[];
  setInvoicesToNumber: React.Dispatch<React.SetStateAction<MeterInvoice[]>>;
};

const invoicesNumberingContext = createContext<InvoicesNumberingContext>({
  modalVisible: false,
  setModalVisible: () => {
    throw new Error('setModalVisible not implemented');
  },
  finalAction: () => {},
  setFinalAction: () => {
    throw new Error('setFinalAction not implemented');
  },

  actionType: null,
  setActionType: () => {
    throw new Error('setActionType not implemented');
  },

  invoicesToNumber: [],
  setInvoicesToNumber: () => {
    throw new Error('setInvoicesToNumber not implemented');
  },
});

/**
 * Provides the context for the invoice numbering
 */

export const InvoicesNumberingProvider = ({
  children,
  invoiceType,
}: React.PropsWithChildren<{ invoiceType: MeterInvoiceSource }>) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [finalAction, setFinalAction] = useState<(() => void) | null>(() => {});
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [invoicesToNumber, setInvoicesToNumber] = useState<MeterInvoice[]>([]);

  const closeModal = useCallback(() => setModalVisible(false), []);

  return (
    <invoicesNumberingContext.Provider
      value={{
        modalVisible,
        setModalVisible,
        finalAction,
        setFinalAction,
        actionType,
        setActionType,
        invoicesToNumber,
        setInvoicesToNumber,
      }}
    >
      <InvoicesNumberingModal isOpen={modalVisible} closeModal={closeModal} invoiceType={invoiceType} />
      {children}
    </invoicesNumberingContext.Provider>
  );
};

type HandleClick = (callback: () => void, actionType: ActionType) => () => void;

const isToNumberByAction = (action: ActionType) => (invoice?: MeterInvoice) => {
  if (!invoice || !requiresNumbering(invoice) || action === 'nominate_to_edf_oa') return false;

  return true;
};

/**
 * Check if the invoices needs to be numbered or not before doing a action defined by the callback
 */
export default function useInvoiceNumbering(): {
  invoicesToNumber: MeterInvoice[];
  setInvoicesToNumber: React.Dispatch<React.SetStateAction<MeterInvoice[]>>;
  handleClick: HandleClick;
  finalAction: InvoicesNumberingContext['finalAction'];
  actionType: InvoicesNumberingContext['actionType'];
} {
  const {
    setModalVisible,
    setFinalAction,
    finalAction,
    actionType,
    setActionType,
    setInvoicesToNumber,
    invoicesToNumber,
  } = useContext(invoicesNumberingContext);

  const { selectedInvoicesUuids } = useContext(selectInvoicesContext);
  const { allInvoices } = useInvoicesContext();
  const selectedInvoices = selectedInvoicesUuids
    .map((uuid) => allInvoices.find((i) => i.uuid === uuid))
    .filter((invoice): invoice is MeterInvoice => !!invoice);

  /**
   * If there are invoices to number, open the modal and set the callback to execute after numbering
   * @param callback The method we want to execute after the numbering
   */
  const handleClick: HandleClick = (callback, actionType: ActionType) => () => {
    setActionType(actionType);
    const _invoicesToNumber = selectedInvoices
      .filter(isToNumberByAction(actionType))
      .filter((v) => v !== undefined) as MeterInvoice[];
    setInvoicesToNumber(_invoicesToNumber);

    if (_invoicesToNumber.length > 0) {
      setFinalAction(() => callback);
      setModalVisible(true);
    } else {
      setFinalAction(() => {});
      callback();
    }
  };

  return { setInvoicesToNumber, invoicesToNumber, handleClick, finalAction, actionType };
}
