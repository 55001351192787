import { Locale } from '@utils/types/common-types';

export const LOCALES: { [key in Locale]?: Highcharts.LangOptions } = {
  fr: {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortMonths: ['Janv.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    decimalPoint: ',',
    invalidDate: 'Format de date invalide',
    loading: 'Chargement',
    noData: 'Aucune donnée disponible',
    resetZoom: 'Réinitialiser zoom',
  },
};
