import React from 'react';
import { BookProvider } from '@components/Books/BookProvider';
import { Tabs } from '@GDM/Tabs';
import Page from '@pages/Page';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import { TABS } from './invoices.constants';
import { InvoicesTabContents } from './InvoicesTabContents';

type Props = {
  locale: Locale;
  user: User;
};

const Invoicing = ({ user }: Props) => {
  return (
    <Page title="common.invoices" user={user} fixedHeightContainer>
      <BookProvider>
        <Tabs tabs={TABS} className="mb-3" noContainer>
          <InvoicesTabContents />
        </Tabs>
      </BookProvider>
    </Page>
  );
};

export default Invoicing;
