import React from 'react';
import contractsContext from '@context/contracts.context';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { ContractsProvider } from './context';
import { TableWrapper } from './Table';

export const Contracts = ({ user }: { user: User }) => {
  return (
    <ContractsProvider user={user}>
      <contractsContext.Consumer>
        {(context) => (
          <Page
            title="sales_management.contracts"
            error={context.error?.message}
            isLoading={context.isLoading}
            user={user}
            layout="no-background"
            fixedHeightContainer
          >
            <TableWrapper showBookFilter />
          </Page>
        )}
      </contractsContext.Consumer>
    </ContractsProvider>
  );
};
