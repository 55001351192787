import React from 'react';
import { Section, sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { VAT_RATES, VAT_EXEMPTION_MESSAGES } from '@utils/constants/countries/vat';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';
import { SectionHeader } from '../../components/SectionHeader';

export const Taxes = ({
  title,
  readOnly,
  globals: { installation, buyer },
  form: { control },
}: ContractFormSectionProps) => {
  const { t } = useTranslation();

  const rate = VAT_RATES[(installation?.country || 'FR') as keyof typeof VAT_RATES] * 100;
  const country = buyer?.country || '';
  const message = VAT_EXEMPTION_MESSAGES[country] || '';

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          <div className={sectionStyles.row}>
            <ListInput
              id="VatRate"
              name="include_vat"
              control={control}
              options={[
                { label: t('contracts.apply_vat', { rate }), value: true },
                { label: message ? t('contracts.exclude_vat', { message }) : 'common.no', value: false },
              ]}
              label="contracts.vat_application"
              readOnly={readOnly}
            />
          </div>
        </Section>
      }
    />
  );
};
